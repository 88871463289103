import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  highlight(e) {
    e.preventDefault();

    const body = document.getElementsByTagName('body')[0];
    const className = 'highlight-rainforest-component';

    if (body.classList.contains(className)) {
        body.classList.remove(className);
    } else {
        body.classList.add(className);
    }
  }
}
